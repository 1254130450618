<template>
  <Header title="Platinum" />
  <!----- SLIDER SECTION ----->
  <section class="inner_banner_slider_section top_margin">
    <div class="inner_banner_slider_row">
      <div class="inner_banner_slider_col">
        <div class="inner_banner_slider_block">
          <div class="gallery inner_banner_slider">
            <Carousel
              ref="villas"
              :items-to-show="1"
              :wrap-around="true"
              :autoplay="2000"
            >
              <Slide key="1">
                <img
                  src="../../src/assets/images/event_img/event-slider-5.jpg"
                />
              </Slide>
              <Slide key="2">
                <img
                  src="../../src/assets/images/event_img/event-slider-6.jpg"
                />
              </Slide>
              <Slide key="3">
                <img
                  src="../../src/assets/images/event_img/event-slider-1.jpg"
                />
              </Slide>
              <Slide key="4">
                <img
                  src="../../src/assets/images/event_img/event-slider-2.jpg"
                />
              </Slide>
            </Carousel>
          </div>
        </div>
      </div>
      <div class="inner_banner_col">
        <div class="inner_banner_block">
          <p class="body_heading tc">Events @ Rio</p>
        </div>
      </div>
    </div>
  </section>
  <!-- ----------- data & Explore the Villa & Amenities ----------- -->
  <section class="villa_data_section row_padding lef_img villa_bg_img">
    <div class="villa_data_row main_container">
      <div class="villa_data_col villa_left_col">
        <div class="villa_left_block">
          <div class="body_heading_block desk_heading">
            <p class="body_heading">Destination. Luxury. Respite.</p>
            <div class="small_body_font">
              <p>
                A perfect blend of contemporary design and functionality, our
                platinum villas come with breathtaking beauty and excellence.
                Themed as per the Bali escapades, a calming view can be
                experienced from every room, along with contemporary amenities
                including opulent beds and LED television. The full-sized
                windows offer an abundance of natural light for you to
                rejuvenate and connect with nature.
              </p>
              <p>
                The 2-storey 3 BHK villa comes with a swimming pool and is
                nested amidst lush greenery and scenic beauty. Experience an
                invigorating and intimate relaxed getaway with your loved ones
                with our exclusive facilities and amenities.
              </p>
              <p>
                Being one of the best villa on rent in Surat, we offer you the
                perfect escape from the hustle-bustle of city life. Enjoy in the
                jacuzzi, watch movies, and enjoy the perfect time with your
                family! We have curated the perfect slice of serenity, beauty,
                peace, and entertainment to make your stay a memorable one!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="villa_data_col villa_right_col" id="wrapper_box">
        <div class="villa_right_block" id="sticky_box">
          <div class="villa_right_outter">
            <div class="price_box">
              <div class="price_rate_box">
                <p class="new_price font_24 wt">Book Your Event!</p>
              </div>
            </div>
            <div class="separater"></div>
            <Datepicker
              type="date"
              :min="new Date().toISOString().substr(0, 10)"
              @closed="dateChange()"
              v-model="form.rangeDate"
              placeholder="Check In Check Out"
              format="dd-MM-yyyy"
              value-format="dd-MM-yyyy"
              modelAuto
              range
              multiCalendars
              multiCalendarsSolo
              :disabled="true"
            />
            <div
              class="invalid-feedback fw-bold fst-italic text-danger mt-1"
              :style="{ display: checkInDateError }"
            >
              Please enter checkin checkout Date.
            </div>
            <div class="guest_box box_border">
              <div class="flex items-center justify-center gap-x-4">
                <div
                  class="dd_inner"
                  style="align-items: center !important"
                  v-show="is_adult"
                >
                  <div class="d-flex">
                    <p class="body_font">Adults</p>
                  </div>
                  <button
                    class="px-4 py-2 text-white bg-red-600 focus:outline-none increment"
                    @click="decrement"
                  >
                    -
                  </button>
                  {{ form.total_adult }}
                  <button
                    class="px-4 py-2 text-white bg-blue-600 focus:outline-none increment"
                    @click="increment"
                  >
                    +
                  </button>
                </div>
                <div
                  class="dd_inner"
                  style="
                    align-items: center !important;
                    margin-top: 5px !important;
                  "
                  v-show="is_child"
                >
                  <div class="d-flex">
                    <p class="body_font">Childs</p>
                  </div>
                  <button
                    class="px-4 py-2 text-white bg-red-600 focus:outline-none increment"
                    @click="decrementChild"
                  >
                    -
                  </button>
                  {{ form.total_child }}
                  <button
                    class="px-4 py-2 text-white bg-blue-600 focus:outline-none increment"
                    @click="incrementChild"
                  >
                    +
                  </button>
                </div>
              </div>
              <!-- end -->
            </div>
            <div
              class="invalid-feedback fw-bold fst-italic text-danger mt-2"
              :style="{ display: totalAdultError }"
            >
              Please Select 1 Adult and Child.
            </div>
            <div
              class="guest_box box_border"
              style="border: none !important"
              v-show="loader"
            >
              <div
                class="flex items-center justify-center gap-x-4"
                style="text-align: center"
              >
                <span style="margin-top: 10px">--- LOADING ---</span>
              </div>
            </div>
            <div class="price_cal_outter">
              <div class="price_cal_inner">
                <!-- <div class="price_cal_box">
                                            <p class="price_cal_title font_20">Festival Charge</p>
                                            <p class="price_cal_num font_20">₹{{bookingResult.festival_charge}}</p>
                                        </div> -->
                <div class="price_cal_box">
                  <p class="price_cal_title font_20">Price</p>
                  <p class="price_cal_num font_20">₹{{ price ? price : 0 }}</p>
                </div>
                <!-- <div class="price_cal_box">
                  <p class="price_cal_title font_20">Extra Person Charge</p>
                  <p class="price_cal_num font_20" v-if="form.booking">
                    ₹{{
                      form.booking.extra_person_charge
                        ? form.booking.extra_person_charge
                        : 0
                    }}
                  </p>
                  <p class="price_cal_num font_20" v-else>₹0</p>
                </div> -->
                <!-- <div class="price_cal_box">
                  <p class="price_cal_title font_20">
                    GST {{ "(" + form.package.gst + "%" + ")" }}
                  </p>
                  <p class="price_cal_num font_20" v-if="form.booking">
                    ₹{{ form.booking.gst_tax ? form.booking.gst_tax : 0 }}
                  </p>
                  <p class="price_cal_num font_20" v-else>₹0</p>
                </div> -->
              </div>
            </div>
            <div class="separater"></div>
            <div class="total_price_cal_box price_cal_box">
              <p class="total_price_cal_title box_title_text wt">Total Price</p>
              <p
                class="total_price_cal_num box_title_text wt price_cal_num"
                v-if="form.booking"
              >
                ₹{{ form.booking.total ? form.booking.total : 0 }}
              </p>
              <p
                class="total_price_cal_num box_title_text wt price_cal_num"
                v-else
              >
                ₹0
              </p>
            </div>
            <div class="btn_block btn_center_block">
              <div class="btn_border block_cta big_btn">
                <a
                  href="javascript:void(0)"
                  @click="book"
                  class="site_button"
                  v-if="
                    form.package &&
                    form.package.available_slot &&
                    form.package.available_slot >= form.total_person
                  "
                >
                  <div class="site_button_div">
                    <span class="font_20">Book Now</span>
                  </div>
                </a>
                <a class="site_button disabled" v-else>
                  <div class="site_button_div">
                    <span class="font_20">Booking Not Available</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="right_outter_02">
            <div class="right_inner_02">
              <div class="right_inner_block">
                <svg
                  width="22"
                  height="23"
                  viewBox="0 0 22 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.35082 5.41C0.35082 3.95 0.77082 2.81 1.61082 1.99C2.47082 1.15 3.57082 0.729998 4.91082 0.729998C6.25082 0.729998 7.34082 1.15 8.18082 1.99C9.04082 2.81 9.47082 3.95 9.47082 5.41C9.47082 6.87 9.04082 8.02 8.18082 8.86C7.34082 9.7 6.25082 10.12 4.91082 10.12C3.57082 10.12 2.47082 9.7 1.61082 8.86C0.77082 8.02 0.35082 6.87 0.35082 5.41ZM18.6508 1.12L6.68082 22H3.32082L15.2908 1.12H18.6508ZM4.91082 2.8C3.65082 2.8 3.02082 3.67 3.02082 5.41C3.02082 7.17 3.65082 8.05 4.91082 8.05C5.51082 8.05 5.97082 7.84 6.29082 7.42C6.63082 6.98 6.80082 6.31 6.80082 5.41C6.80082 3.67 6.17082 2.8 4.91082 2.8ZM12.5608 17.68C12.5608 16.22 12.9808 15.08 13.8208 14.26C14.6808 13.42 15.7808 13 17.1208 13C18.4408 13 19.5208 13.42 20.3608 14.26C21.2208 15.08 21.6508 16.22 21.6508 17.68C21.6508 19.14 21.2208 20.29 20.3608 21.13C19.5208 21.97 18.4408 22.39 17.1208 22.39C15.7808 22.39 14.6808 21.97 13.8208 21.13C12.9808 20.29 12.5608 19.14 12.5608 17.68ZM17.0908 15.07C15.8308 15.07 15.2008 15.94 15.2008 17.68C15.2008 19.42 15.8308 20.29 17.0908 20.29C18.3508 20.29 18.9808 19.42 18.9808 17.68C18.9808 15.94 18.3508 15.07 17.0908 15.07Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div class="right_inner_block">
                <p class="box_title_text">EARLY BIRD</p>
                <p class="btn_text">
                  Use Code: EARLYBIRD | Book and get 15% off upto ₹20,000.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Download />
  <Footer />
  <Login :popupMobile="popupMobile" :queryData="queryData"  @update="onUpdate($event)" />
</template>
<script>
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import Login from "../components/LoginPopup.vue";
import filler_img from "../assets/images/filler_img.png";
import GP_btn_01 from "../assets/images/gp-img.png";
import AS_btn_01Png from "../assets/images/as-img.png";
import Form from "vform";
import { ref } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
// import { isProxy, toRaw } from "vue";
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

import Download from "@/pages/Download.vue";

export default {
  name: "Platinum",
  components: { Header, Footer, Datepicker, Login, Carousel, Slide, Download },
  data() {
    return {
      filler_img: filler_img,
      GP_btn_01: GP_btn_01,
      AS_btn_01Png: AS_btn_01Png,
      is_adult: true,
      is_child: true,
      form: new Form({
        id: this.$route.params.event_id,
        rangeDate: ref(),
        check_in_date: "",
        check_out_date: "",
        total_person: 0,
        total_adult: 0,
        total_child: 0,
        package: {},
        booking: {
          total: 0,
          gst_tax: 0,
          extra_person_charge: 0,
          festival_charge: 0,
          gst_tax_percent: 0,
          type: "EventBooking",
        },
      }),
      token: localStorage.getItem("auth_cus_token"),
      checkInDateError: "none",
      totalAdultError: "none",
      totalQTYError: "none",
      popupMobile: false,
      queryData: "",
      bookingResult: {
        total: 0,
        extra_person_charge: 0,
        festival_charge: 0,
        gst_tax: 0,
        gst_tax_percent: 0,
      },
      loader: false,
      price: 0,
    };
  },
  beforeMount() {
    setTimeout(() => {
      const recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute("src", "./js/script.js");
      document.head.appendChild(recaptchaScript);
    }, 30);
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (window.screen.width > 768) {
      window.scrollTo({ top: 740, behavior: "smooth" });
    }
    this.getPackageDetail();
  },
  methods: {
    onUpdate(updatedData) {
      this.popupMobile = updatedData;
    },
    getTotalPrice() {
      var adult_price = this.form.package.adult_price
        ? parseFloat(this.form.package.adult_price)
        : parseFloat(0);
      var child_price = this.form.package.child_price
        ? parseFloat(this.form.package.child_price)
        : parseFloat(0);

      var total_price = 0;

      if (this.is_adult) {
        total_price += adult_price * parseInt(this.form.total_adult);
      }

      if (this.is_child) {
        total_price += child_price * parseInt(this.form.total_child);
      }

      this.price = total_price;
      this.form.booking.gst_tax =
        (total_price * parseFloat(this.form.package.gst)) / 100;
      this.form.booking.total = total_price + this.form.booking.gst_tax;
      this.form.booking.gst_tax_percent = parseFloat(this.form.package.gst);

      this.form.total_person =
        parseInt(this.form.total_adult) + parseInt(this.form.total_child);
    },
    async getPackageDetail() {
      this.loader = true;
      await axios
        .get(
          process.env.VUE_APP_API_CUS_URL +
            "/get-events/" +
            this.$route.params.event_id,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          console.log("response: ", response);
          this.loader = false;
          if (response.data.code == 200) {
            this.form.package = response.data.data;
            this.form.package.gst = 0;
            const dateArr = [];
            if (response.data.data.from_date) {
              this.form.check_in_date = response.data.data.from_date;
              dateArr.push(moment.utc(response.data.data.from_date)._d);
            }
            if (response.data.data.to_date) {
              this.form.check_out_date = response.data.data.to_date;
              dateArr.push(moment.utc(response.data.data.to_date)._d);
            }
            if (response.data.data.from_date != response.data.data.to_date) {
              this.form.rangeDate = dateArr;
            } else {
              this.form.rangeDate = moment.utc(response.data.data.from_date)._d;
            }
          } else {
            this.$toast.error(response.data?.message);
          }
        });
    },
    checkAuth() {
      if (
        this.form.id > 0 &&
        this.form.check_in_date.length > 0 &&
        this.form.check_out_date.length > 0 &&
        this.form.total_adult > 0 &&
        this.form.qty > 0
      ) {
        this.getBookingResults();
      }
      return 1;
    },
    async getBookingResults() {
      this.loader = true;
      await axios
        .post(
          process.env.VUE_APP_API_CUS_URL + "/villa-booking-total",
          {
            villa_booking: [
              {
                type: "EventBooking",
                villa_type_id: "",
                person: this.form.total_adult,
                check_in_date: this.form.check_in_date,
                check_out_date: this.form.check_out_date,
              },
            ],
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then(() => {
          this.loader = false;
        });
    },
    dateChange() {
      this.checkAuth();
    },
    increment() {
      this.form.total_adult++;
      this.getTotalPrice();
      this.checkAuth();
    },
    decrement() {
      if (this.form.total_adult > 0) {
        this.form.total_adult--;
      }
      this.getTotalPrice();
      this.checkAuth();
    },
    incrementChild() {
      this.form.total_child++;
      this.getTotalPrice();
      this.checkAuth();
    },
    decrementChild() {
      if (this.form.total_child > 0) {
        this.form.total_child--;
      }
      this.getTotalPrice();
      this.checkAuth();
    },
    incrementQTY() {
      this.form.qty++;
      this.checkAuth();
    },
    decrementQTY() {
      if (this.form.qty > 0) {
        this.form.qty--;
      }
      this.checkAuth();
    },
    ...mapActions(["addPlatinum"]),
    book() {
      this.totalAdultError = "none";
      if (!this.token) {
        // localStorage.setItem("id", this.form.id);
        // localStorage.setItem("check_in_date", this.form.check_in_date);
        // localStorage.setItem("check_out_date", this.form.check_out_date);
        // localStorage.setItem("total_adult", this.form.total_adult);
        // localStorage.setItem("total_child", this.form.total_child);
        // localStorage.setItem("type", "EventBooking");
        // localStorage.setItem("gst_tax", this.form.booking.gst_tax);
        // localStorage.setItem(
        //   "gst_tax_percent",
        //   this.form.booking.gst_tax_percent
        // );
        // localStorage.setItem("total", this.form.booking.total);
        // localStorage.setItem(
        //   "extra_person_charge",
        //   this.form.booking.extra_person_charge
        // );
        // localStorage.setItem(
        //   "festival_charge",
        //   this.form.booking.festival_charge
        // );
        // localStorage.setItem(
        //   "gst_tax_percent",
        //   this.form.booking.gst_tax_percent
        // );

        this.checkInDateError = "none";
        if (
          this.form.check_in_date.length === 0 ||
          this.form.check_out_date.length === 0
        ) {
          this.checkInDateError = "block";
          return false;
        }
        this.totalAdultError = "none";

        if (this.form.total_child > 0) {
          if (this.form.total_adult == 0) {
            this.totalAdultError = "block";
            return false;
          }
        }

        if (this.form.total_adult == 0) {
          this.totalAdultError = "block";
          return false;
        }

        this.queryData = { 
          name: "dashboard", 
          params: { 
            bookingtype: "EventBooking" 
          }, 
          query: { 
            event_id: this.form.package.id, 
            adults: this.form.total_adult,
            childs: this.form.total_child,
            date: this.form.check_in_date,
          } 
        }

        this.popupMobile = !this.popupMobile;
      } else if (
        this.form.check_in_date.length === 0 ||
        this.form.check_out_date.length === 0
      ) {
        this.checkInDateError = "block";
        return false;
      } else {
        if (this.form.total_child > 0) {
          if (this.form.total_adult == 0) {
            this.totalAdultError = "block";
            return false;
          }
        } else if (this.form.total_adult == 0) {
          this.totalAdultError = "block";
          return false;
        }

        this.$router.push({ name: "dashboard", params: { bookingtype: "EventBooking" }, query: { 
          event_id: this.form.package.id, 
          adults: this.form.total_adult,
          childs: this.form.total_child,
          date: this.form.check_in_date,
        } });

        // this.addPlatinum(this.form);
        // let rawData = this.allPlatinums;
        // if (isProxy(this.allPlatinums)) {
        //   rawData = toRaw(this.allPlatinums);
        // }
        // if (rawData[0].id) {
        //   this.$router.push({ name: "dashboard" });
        // }
      }
    },
  },
  computed: mapGetters(["allPlatinums"]),
};
</script>

<style>
.box_border {
  margin-bottom: 10px;
}

.villa_bg_img {
  background-size: auto;
}

.dp__input_icons {
  height: 28px;
  width: 28px;
  color: #fff;
  margin-top: 0px;
  height: 42px !important;
}

.dp__input {
  background: #7da741;
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 10px 20px 10px 45px;
}

.dp__input::placeholder {
  color: #fff;
  font-size: 20px !important;
  margin: auto 0 auto 25px !important;
  opacity: 1 !important;
  padding-left: 50px !important;
}

.dp__input_wrap svg path {
  fill: black !important;
  fill: var(--dp-icon-color) !important;
}

.villa_right_block .booking_form_block .dp__input_wrap svg {
  width: 20px;
  height: 20px !important;
  position: absolute !important;
  top: 5px;
}

.villa_right_block .dp__input_wrap {
  background: transparent !important;
  display: inline-block;
}

.villa_right_block svg.dp__icon.dp__input_icon.dp__input_icons path {
  fill: white !important;
}

.dp__main.dp__theme_light {
  width: 100%;
  background: transparent !important;
  display: block;
  padding: 0px !important;
}

.villa_right_block .dp__main.dp__theme_light input {
  background: transparent !important;
  margin-top: 0px !important;
  background: #7da741 !important;
  border: 2px solid white !important;
}

.increment {
  width: 30px;
  height: 30px;
  background: transparent;
  border-radius: 4px;
  padding: 3px;
  border: 1px solid #fff;
  text-align: center;
  margin: auto;
  cursor: pointer;
  color: #fff;
}

div#lightbox img {
  height: 450px !important;
  object-fit: cover !important;
  max-width: 550px !important;
  display: block;
  margin: 0 auto;
}

div#lightbox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
}

a.site_button:hover {
  background: #fff !important;
  border: 1px solid #fff;
  color: #000 !important;
  transition: 1s !important;
}

a.site_button.disabled {
  opacity: 0.5;
}

a.site_button.disabled:hover {
  background: #fff !important;
  border: 1px solid #fff;
  color: #000 !important;
  transition: 1s !important;
  opacity: 0.5;
}

.dp__input_icon_pad {
  color: #fff;
}

@media all and (min-width: 320px) and (max-width: 767px) {
  .guest_box.box_border {
    margin-top: 15px;
  }

  .dp__flex_display {
  }

  .dp__instance_calendar {
    /* flex: 1 !important; */
    /* width: 50%; */
  }

  .dp__cell_inner {
    width: 24px !important;
    height: 20px !important;
    font-size: 11px;
  }

  .dp__action_row {
    margin-top: 0px;
  }

  .dp__calendar_header_item {
    width: 24px !important;
    height: 20px;
    font-size: 11px;
  }

  .dp__flex_display {
    flex-direction: initial !important;
  }
}
</style>
